import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "./context/UserContext";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useContext(UserContext);
  const location = useLocation();

  if (!isLoggedIn) {
    // Redirect to login with the `next` parameter
    return <Navigate to={`/zaloguj-sie?next=${location.pathname}`} />;
  }

  return children;
};

export default ProtectedRoute;
