import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserContext } from "./context/UserContext.js";
import ProtectedRoute from "./ProtectedRoute";
import ScrollToTop from "./ScrollToTop";

// ACCOUNTS
const Login = React.lazy(() => import("./pages/Accounts/Login/Index.js"));
const Register = React.lazy(() => import("./pages/Accounts/Register/Index.js"));
const ForgetPassword = React.lazy(() =>
  import("./pages/Accounts/ForgetPassword/Index.js")
);

// CORE
const Home = React.lazy(() => import("./pages/Core/Home/Index.js"));
const Studio = React.lazy(() => import("./pages/Core/Studio/Index.js"));
const OfferDetail = React.lazy(() => import("./pages/Core/Offer/Index.js"));
const AboutUs = React.lazy(() => import("./pages/Core/AboutUS/Index.js"));
const Contact = React.lazy(() => import("./pages/Core/Contact/Index.js"));
const KarolPage = React.lazy(() => import("./pages/Core/KarolPage/Index.js"));
const SearchResults = React.lazy(() =>
  import("./pages/Core/SearchResults/Index.js")
);
const SellerPage = React.lazy(() => import("./pages/Core/SellerPage/Index.js"));
const Podobne = React.lazy(() => import("./pages/Core/Podobne/Index.js"));
const Category = React.lazy(() => import("./pages/Core/Category/Index.js"));
const PrivacyPolicy = React.lazy(() =>
  import("./pages/Core/PrivacyPolicy/Index.js")
);
const Regulations = React.lazy(() =>
  import("./pages/Core/Regulations/Index.js")
);
const NotFound = React.lazy(() => import("./pages/Core/404/Index.js"));
const ServerError = React.lazy(() => import("./pages/Core/500/Index.js"));

// USER
const AddOffer = React.lazy(() => import("./pages/User/AddOffer/Index.js"));
const AddOfferThankYou = React.lazy(() =>
  import("./pages/User/AddOfferThankYou/Index.js")
);
const Chat = React.lazy(() => import("./pages/User/Chat/Index.js"));
const Offers = React.lazy(() => import("./pages/User/Offers/Index.js"));
const Profile = React.lazy(() => import("./pages/User/Profile/Index.js"));
const UpdateOffer = React.lazy(() =>
  import("./pages/User/UpdateOffer/Index.js")
);

// ADMIN
const Admin = React.lazy(() => import("./pages/Admin/Home/Index.js"));
const AdminCategories = React.lazy(() =>
  import("./pages/Admin/Categories/Index.js")
);
const AdminOffers = React.lazy(() => import("./pages/Admin/Offers/Index.js"));
const AdminUsers = React.lazy(() => import("./pages/Admin/Users/Index.js"));
const AdminVariables = React.lazy(() =>
  import("./pages/Admin/Variables/Index.js")
);
const AdminSurveyResults = React.lazy(() =>
  import("./pages/Admin/SurveyResults/Index.js")
);

const AdminCategory = React.lazy(() => import("./pages/Admin/Category/Index"));
const AdminOffer = React.lazy(() => import("./pages/Admin/Offer/Index"));
const AdminUser = React.lazy(() => import("./pages/Admin/User/Index"));
const AdminVariable = React.lazy(() => import("./pages/Admin/Variable/Index"));

const AdminAddCategory = React.lazy(() =>
  import("./pages/Admin/AddCategory/Index")
);
const AdminAddZmienna = React.lazy(() =>
  import("./pages/Admin/VariableAdd/Index.js")
);
const AdminNaruszenia = React.lazy(() =>
  import("./pages/Admin/Violations/Index.js")
);

function App() {
  const { user } = useContext(UserContext);

  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* CORE */}
          <Route path="/" element={<Home />} />
          <Route path="/studio" element={<Studio />} />
          <Route path="/oferty" element={<SearchResults />} />
          <Route path="/kategorie/:cat_id" element={<Category />} />
          <Route path="/kategorie/:cat_id/:sub_cat_id" element={<Category />} />
          <Route
            path="/kategorie/:cat_id/:sub_cat_id/:final_cat_id"
            element={<Category />}
          />
          <Route
            path="/oferta/:offer_id/:offer_slug"
            element={<OfferDetail />}
          />
          <Route path="/podobne/:cat_id" element={<Podobne />} />
          <Route path="/sprzedawca/:seller_id" element={<SellerPage />} />
          <Route path="/o-nas" element={<AboutUs />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/karol" element={<KarolPage />} />
          <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />
          <Route path="/regulamin" element={<Regulations />} />
          <Route path="/server-error" element={<ServerError />} />

          {/* Accounts */}
          <Route path="/zaloguj-sie" element={<Login />} />
          <Route path="/zarejestruj-sie" element={<Register />} />
          <Route path="/resetuj-haslo" element={<ForgetPassword />} />

          {/* USER */}
          <Route
            path="/moje-konto/dodaj-ogloszenie/"
            element={
              <ProtectedRoute>
                <AddOffer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/moje-konto/dodaj-ogloszenie/dziekuje"
            element={
              <ProtectedRoute>
                <AddOfferThankYou />
              </ProtectedRoute>
            }
          />
          <Route
            path="/moje-konto/edytuj-profil/"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/moje-konto/panel/"
            element={
              <ProtectedRoute>
                <Offers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/moje-konto/wiadomosci/"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/moje-konto/edytuj/:id/:slug"
            element={
              <ProtectedRoute>
                <UpdateOffer />
              </ProtectedRoute>
            }
          />

          {(user?.is_superuser || user?.is_staff) && (
            <>
              <Route
                path="/admin/"
                element={
                  <ProtectedRoute>
                    <Admin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/kategorie"
                element={
                  <ProtectedRoute>
                    <AdminCategories />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/oferty"
                element={
                  <ProtectedRoute>
                    <AdminOffers />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/uzytkownicy"
                element={
                  <ProtectedRoute>
                    <AdminUsers />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/zmienne"
                element={
                  <ProtectedRoute>
                    <AdminVariables />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/wyniki-ankiety"
                element={
                  <ProtectedRoute>
                    <AdminSurveyResults />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/kategorie/dodaj"
                element={
                  <ProtectedRoute>
                    <AdminAddCategory />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/kategorie/:cat_id"
                element={
                  <ProtectedRoute>
                    <AdminCategory />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/oferty/:offer_id"
                element={
                  <ProtectedRoute>
                    <AdminOffer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/uzytkownicy/:user_id"
                element={
                  <ProtectedRoute>
                    <AdminUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/zmienne/dodaj"
                element={
                  <ProtectedRoute>
                    <AdminAddZmienna />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/zmienne/:variable_id"
                element={
                  <ProtectedRoute>
                    <AdminVariable />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/naruszenia"
                element={
                  <ProtectedRoute>
                    <AdminNaruszenia />
                  </ProtectedRoute>
                }
              />
            </>
          )}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
