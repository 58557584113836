import { SettingsURL } from "../settings";
import { fetchWithRedirect } from "./base";

export const refreshAccessToken = async (refreshToken) => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ refresh: refreshToken }),
  };
  return await fetchWithRedirect(
    `${SettingsURL}accounts/token/refresh/`,
    options
  );
};

export const googleApiLogin = async (data) => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return await fetchWithRedirect(`${SettingsURL}accounts/google/`, options);
};

export const facebookApiLogin = async (data) => {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return await fetchWithRedirect(`${SettingsURL}accounts/facebook/`, options);
};

export const accountActivation = async (link) => {
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return await fetchWithRedirect(
    `${SettingsURL}accounts/activate/${link}/`,
    options
  );
};

export async function signIn(email, password, capcha) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body: JSON.stringify({
      email,
      password,
      "g-recaptcha-response": capcha,
    }),
  };
  return await fetchWithRedirect(`${SettingsURL}accounts/login/`, options);
}

export async function forgetPassword(data_in) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data_in),
  };
  return await fetchWithRedirect(
    `${SettingsURL}accounts/password-reset/`,
    options
  );
}

export async function forgetPasswordCode(code, email) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ code }),
  };
  return await fetchWithRedirect(
    `${SettingsURL}accounts/password-reset/confirm/${email}/`,
    options
  );
}

export async function changePassword(email, code, data_in) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data_in),
  };
  return await fetchWithRedirect(
    `${SettingsURL}accounts/change-password/${email}/${code}/`,
    options
  );
}

export async function variableContact() {
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return await fetchWithRedirect(`${SettingsURL}contact/`, options);
}

export async function SignUp(data_in) {
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data_in),
  };
  return await fetchWithRedirect(`${SettingsURL}accounts/register/`, options);
}

export async function getProfileData(token) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(`${SettingsURL}user/me/`, options);
}

export async function updateProfileData(token, _data) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(_data),
  };
  return await fetchWithRedirect(`${SettingsURL}user/me/`, options);
}

export async function updatePasswordProfileData(token, _data) {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(_data),
  };
  return await fetchWithRedirect(`${SettingsURL}user/me/`, options);
}

export async function deleteProfileData(token) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(`${SettingsURL}user/me/`, options);
}

export async function uploadProfilePicture(token, _data) {
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: _data,
  };
  return await fetchWithRedirect(`${SettingsURL}user/me/picture/`, options);
}

export async function removeProfilePicture(token) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(`${SettingsURL}user/me/picture/`, options);
}

export async function addOfferGet(token) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(`${SettingsURL}user/add-offer/`, options);
}

export async function updateOfferGet(token, offer_id) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/update-offer/${offer_id}/`,
    options
  );
}

export async function addOffer(token, data_in) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data_in),
  };
  return await fetchWithRedirect(`${SettingsURL}user/add-offer/`, options);
}

export async function updateOffer(token, data_in) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data_in),
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/update-offer/${data_in.id}/`,
    options
  );
}

export async function addImagesOffer(token, offer_id, images, orderData) {
  const formData = new FormData();

  // Append images for upload
  images.forEach((image) => {
    formData.append("images", image.file ? image.file : image.image);
  });

  // Append order data for reordering
  formData.append("order", JSON.stringify(orderData));

  const options = {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: formData,
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/add-offer/image/${offer_id}/`,
    options
  );
}

export async function getUserOffers(token) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(`${SettingsURL}user/`, options);
}

export async function closeOffer(token, offer_id, data_in) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data_in),
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/close/survey/${offer_id}/`,
    options
  );
}

export async function deactivateOffer(token, offer_id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/deactivate/${offer_id}/`,
    options
  );
}

export async function activateOffer(token, offer_id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/activate/${offer_id}/`,
    options
  );
}

export async function archivizeOffer(token, offer_id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/archive/${offer_id}/`,
    options
  );
}

export async function unArchivizeOffer(token, offer_id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/unarchive/${offer_id}/`,
    options
  );
}

export async function deleteOffer(token, offer_id) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/remove/${offer_id}/`,
    options
  );
}

export async function removeImage(token, image_id) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/add-offer/image/${image_id}/`,
    options
  );
}

export async function wishlistOffer(token, offer_id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/wishlist/${offer_id}/`,
    options
  );
}

export async function getThreadsData(token) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(`${SettingsURL}user/threads/`, options);
}

export async function getThreadMessages(token, thread_id) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/threads/${thread_id}/`,
    options
  );
}

export async function AddMessageToThread(token, thread_id, _data) {
  const options = {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: _data,
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/threads/${thread_id}/`,
    options
  );
}

export async function updateThread(token, thread_id, _data) {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(_data),
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/threads/${thread_id}/`,
    options
  );
}

export async function setThreadAsUnRead(token, thread_id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/threads/${thread_id}/unread/`,
    options
  );
}

export async function setThreadAsRead(token, thread_id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/threads/${thread_id}/read/`,
    options
  );
}

export async function setThreadAsArchived(token, thread_id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/threads/${thread_id}/archive/`,
    options
  );
}

export async function setThreadAsUnArchived(token, thread_id) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/threads/${thread_id}/unarchive/`,
    options
  );
}

export async function getMyAlerts(token) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(`${SettingsURL}user/alert/`, options);
}

export async function removeMyAlerts(token, alert_id) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}user/alert/${alert_id}/`,
    options
  );
}

export async function createAlert(
  token,
  cat_slug,
  sub_cat_slug,
  final_cat_slug
) {
  const slug_cat = cat_slug ? `${cat_slug}/` : "";
  const slug_sub_cat = sub_cat_slug ? `${sub_cat_slug}/` : "";
  const slug_final_cat = final_cat_slug ? `${final_cat_slug}/` : "";
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetchWithRedirect(
    `${SettingsURL}category/${slug_cat}${slug_sub_cat}${slug_final_cat}`,
    requestOptions
  );
}
