import React, { createContext, useEffect, useState, useCallback } from "react";
import { getProfileData } from "../api/user"; // refreshAccessToken API function

let logoutTimer;

export const UserContext = createContext({
  accessToken: "",
  isLoggedIn: false,
  userSet: () => {},
  user: {},
  logout: () => {},
  login: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime(); // Use the backend expirationTime directly

  return adjExpirationTime - currentTime; // Calculate time left until expiration
};

const retrieveStoredTokens = () => {
  const accessToken = localStorage.getItem("UzyjtoAccessToken");
  const storedExpirationDate = localStorage.getItem(
    "UzyjtoAccessTokenExpirationTime"
  );

  const remainingTime = calculateRemainingTime(storedExpirationDate);
  if (remainingTime <= 3600) {
    // if less than an hour, remove tokens
    localStorage.removeItem("UzyjtoAccessToken");
    localStorage.removeItem("UzyjtoAccessTokenExpirationTime");
    return null;
  }

  return {
    accessToken,
    duration: remainingTime,
  };
};

const retrieveStoredUser = () => {
  const user = localStorage.getItem("UzyjtoUser");
  return user;
};

const removeStoredUser = () => {
  localStorage.removeItem("UzyjtoUser");
  return {};
};

export const UserProvider = (props) => {
  const tokenData = retrieveStoredTokens();
  if (tokenData?.duration <= 0) {
    logoutHandler();
  }
  const userData = retrieveStoredUser();

  let initialAccessToken;
  if (tokenData) {
    initialAccessToken = tokenData.accessToken;
  }

  let initialUser;
  if (userData) {
    try {
      initialUser = JSON.parse(userData);
    } catch (error) {
      console.error("Failed to parse user data:", error);
      initialUser = {};
    }
  }

  const [accessToken, setAccessToken] = useState(initialAccessToken);
  const [user, setUser] = useState(initialUser);

  const userIsLoggedIn = !!accessToken;

  const logoutHandler = useCallback(() => {
    setAccessToken(null);
    setUser(removeStoredUser());
    localStorage.removeItem("UzyjtoAccessToken");
    localStorage.removeItem("UzyjtoAccessTokenExpirationTime");
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const userSetHandler = async () => {
    let user = await getProfileData(accessToken);
    if (user.isOk) {
      setUser(user.data.user);
      localStorage.setItem("UzyjtoUser", JSON.stringify(user.data.user));
    }
  };

  const loginHandler = (accessToken, expirationTime, user) => {
    setAccessToken(accessToken);
    localStorage.setItem("UzyjtoAccessToken", accessToken);
    localStorage.setItem("UzyjtoAccessTokenExpirationTime", expirationTime);

    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(logoutHandler, remainingTime);
    setUser(user);
    localStorage.setItem("UzyjtoUser", JSON.stringify(user));
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    user: user,
    accessToken: accessToken,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    userSet: userSetHandler,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};
