import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import "react-notifications/lib/notifications.css";
import { UserProvider } from "./context/UserContext";
import { NotificationContainer } from "react-notifications";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <GoogleOAuthProvider clientId="793826348700-mq23nibqp027m52688hcpmt9173fajhm.apps.googleusercontent.com">
      <App />
      <NotificationContainer />
    </GoogleOAuthProvider>
  </UserProvider>
);
