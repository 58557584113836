import { useContext } from "react";
import { UserContext } from "../context/UserContext";

// fetchHelper.js
export async function fetchWithRedirect(url, options = {}) {
  try {
    const response = await fetch(url, options);

    // Handle status code 500 by redirecting to an error page
    if (response.status === 500) {
      window.location.href = "/server-error"; // Redirect to error page
      return null; // Prevent further processing
    } else if (response.status === 401) {
      window.location.href = "/zaloguj-sie?next=" + window.location.pathname;
    }

    const data = await response.json();
    return {
      isOk: response?.ok,
      data,
      message: data?.detail,
      status: response?.status,
    };
  } catch (err) {
    console.error(err);
    return {
      isOk: false,
      message: "Wystąpił błąd podczas komunikacji z serwerem.",
    };
  }
}
